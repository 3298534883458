import type Modules from '../types/modules.type';
import colors from './colors';

const modules: Modules = {
    footer: {
        background: '#1D2136',
        linkHeading: {
            color: '#ffc11b'
        },
        governanceNav: {
            background: '#171A2B'
        }
    },
    topBar: {
        background: 'rgba(255, 255, 255, 1)',
        stickyBackground: 'rgba(255, 255, 255, 0.95)'
    },
    siteNavigation: {
        background: '#E6E6E6',
        boxShadow: 'rgb(0 0 0 / 15%) 0rem 0.125rem 0.375rem -0.125rem',
        indicator: {
            background: '#E6E6E6'
        },
        primaryNavigation: {
            background: colors.tertiary.hex,
            color: 'white'
        },
        secondaryNavigation: {
            color: colors.tertiary.hex,
            button: {
                background: 'linear-gradient(204deg, #333333 0%, #575757 100%)'
            }
        },
        backButton: {
            background: '#575757'
        }
    },
    masthead: {
        navItem: {
            background: {
                neutral: 'transparent',
                active: 'white',
                hover: 'white'
            },
            borderColor: '#D0D4D4'
        }
    },
    heroBanner: {
        default: {
            background: '#E8EDED',
            gradient: 'linear-gradient(180deg, #e8eded 0%, white 100%)'
        },
        satellite: {
            color: '#ffffff'
        },
        image: {
            color: '#E8EDED',
            overlay: (opacity = 0.6, angle = 90) =>
                `linear-gradient(${angle}deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, ${opacity}) 60%, rgba(0, 0, 0, 0) 100%)`
        },
        variant: {
            satelliteImage: {
                circle: {
                    background: 'rgba(255, 255, 255, 0.4)'
                }
            },
            courseListing: {
                background:
                    'linear-gradient(0deg, #FFFFFF 0%, #F9FAFA 35.26%, #EEF2F2 64.74%, #E8EDED 100%)'
            },
            overlay: {
                background: 'rgba(0, 0, 0, 0.8)'
            }
        }
    },
    filters: {
        courseListing: {
            dialog: {
                background: 'rgba(51, 55, 60, 0.70)'
            }
        }
    },
    courseCompare: {
        overlay: {
            background: 'rgba(0, 0, 0, 0.5)'
        },
        borderColor: '#fbfbfb'
    },
    virtualTour: {
        overlay: (angle = 90, length = 60, opacity = 0.8) =>
            `linear-gradient(${angle}deg, rgba(${colors.tertiary.rgb}) 0%, rgba(${colors.tertiary.rgb},${opacity}) ${length}%, rgba(0, 0, 0, 0) 100%)`
    },
    accommodationMap: {
        colours: [
            colors.accents[4].hex,
            colors.accents[3].hex,
            colors.accents[6].hex,
            colors.accents[8].hex,
            colors.accents[7].hex
        ]
    },
    siteSearch: {
        website: {
            colour: colors.primary.hex
        },
        staff: {
            colour: colors.staff.hex
        },
        students: {
            colour: colors.primary.hex
        },
        warsash: {
            colour: colors.warsash.hex
        },
        sportscomplex: {
            colour: colors.primary.hex
        }
    }
};

export default modules;

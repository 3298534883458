export const excludeMetadata = [
    '-sys.metadata',
    '-sys.availableLangauges',
    '-sys.workflow',
    '-sys.properties',
    '-sys.owner',
    '-sys.versionStatus',
    '-sys.version.published',
    '-sys.version.modified',
    '-sys.version.versionNo'
];

export const transcriptEmbedFields = [
    'sys',
    'uri',
    'thumbnail',
    'videoTitle',
    'link',
    'tableRowVideoTranscript',
    'tableHeadingsVideoTranscript',
    'timestamp',
    'caption',
    'audioDescription',
    'transcript'
];

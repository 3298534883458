import type Colors from '../types/colors.type';

const colors: Colors = {
    primary: {
        hex: '#CD1423',
        rgb: '205, 20, 35',
        tints: [
            {
                hex: '#D7434F',
                rgb: '215, 67, 79'
            },
            {
                hex: '#E1727B',
                rgb: '225, 114, 123'
            },
            {
                hex: '#EBA1A7',
                rgb: '235, 161, 167'
            },
            {
                hex: '#EC959D',
                rgb: '236, 149, 157'
            }
        ]
    },
    primaryContrast: {
        hex: '#FFFFFF',
        rgb: '255, 255, 255'
    },
    secondary: {
        hex: '#FFFFFF',
        rgb: '255, 255, 255',
        tints: [
            // 0
            {
                hex: '#f2f2f2',
                rgb: '242, 242, 242'
            },
            // 1
            {
                hex: '#E8E9EA',
                rgb: '232, 233, 234'
            },
            // 2
            {
                hex: '#cccccc',
                rgb: '204, 204, 204'
            },
            // 3
            {
                hex: '#b2b2b2',
                rgb: '178, 178, 178'
            },
            // 4
            {
                hex: '#999999',
                rgb: '153, 153, 153'
            },
            // 5
            {
                hex: '#7f7f7f',
                rgb: '127, 127, 127'
            },
            // 6
            {
                hex: '#666666',
                rgb: '102, 102, 102'
            },
            // 7
            {
                hex: '#4c4c4c',
                rgb: '76, 76, 76'
            },
            // 8
            {
                hex: '#333333',
                rgb: '51, 51, 51'
            },
            // 9
            {
                hex: '#191919',
                rgb: '25, 25, 25'
            },
            // 10
            {
                hex: '#D8D8D8',
                rgb: '216, 216, 216'
            },
            // 11
            {
                hex: '#b7c7cf',
                rgb: '183, 199, 207'
            },
            // 12
            {
                hex: '#272727',
                rgb: '39, 39, 39'
            }
        ]
    },
    secondaryContrast: {
        hex: '#000000',
        rgb: '0, 0, 0'
    },
    tertiary: {
        hex: '#1D2136',
        rgb: '29, 33, 54'
    },
    tertiaryContrast: {
        hex: '#FFFFFF',
        rgb: '255, 255, 255'
    },
    accents: [
        // Zero - Black
        {
            hex: '#000000',
            rgb: '0, 0, 0',
            name: 'Black'
        },
        // One - Sky blue
        {
            hex: '#01C3DE',
            rgb: '1, 195, 222',
            name: 'Sky blue'
        },
        // Two - Purple
        {
            hex: '#9243F4',
            rgb: '146, 67, 244',
            name: 'Purple'
        },
        // Three - Yellow
        {
            hex: '#FFC11B',
            rgb: '255, 193, 27',
            name: 'Yellow'
        },
        // Four - Green
        {
            hex: '#C6C745',
            rgb: '198, 199, 69',
            name: 'Green'
        },
        // Five - Sea blue
        {
            hex: '#0F435F',
            rgb: '15, 67, 95',
            name: 'Sea blue'
        },
        // Six - Orange
        {
            hex: '#FF4D00',
            rgb: '255, 77, 0',
            name: 'Orange'
        },
        // Seven - Teal
        {
            hex: '#1F988A',
            rgb: '31, 152, 138',
            name: 'Teal'
        },
        // Eight - Pink
        {
            hex: '#D82F89',
            rgb: '216, 47, 137',
            name: 'Pink'
        },
        // Nine - Burgundy
        {
            hex: '#531623',
            rgb: '83, 22, 35',
            name: 'Burgundy'
        },
        // Ten - Petrol Blue
        {
            hex: '#083237',
            rgb: '8, 50, 55',
            name: 'Petrol blue'
        },
        // Eleven - Pastel Yellow
        {
            hex: '#FFE7A2',
            rgb: '255, 231, 162',
            name: 'Pastel yellow'
        },
        // Twelve - Pastel Pink
        {
            hex: '#EFACD0',
            rgb: '239, 172, 208',
            name: 'Pastel pink'
        },
        // Thirteen - Pastel Blue
        {
            hex: '#99E5EF',
            rgb: '153, 229, 239',
            name: 'Pastel blue'
        },
        // Fourteen - Strong Pink
        {
            hex: '#C9267D',
            rgb: '201, 38, 125',
            name: 'Strong pink'
        },
        // Fifteen - Pastel Purple
        {
            hex: '#D3B4FB',
            rgb: '211, 180, 251',
            name: 'Pastel purple'
        },
        // Sixteen - Payne's gray
        {
            hex: '#396379',
            rgb: '57, 99, 121',
            name: `Payne's gray`
        },
        // Seventeen - Keppel
        {
            hex: '#4CADA1',
            rgb: '76, 173, 161',
            name: 'Keppel'
        },
        //Eighteen - Crayola Orange
        {
            hex: '#FF7133',
            rgb: '255, 113, 51',
            name: 'Crayola orange'
        },
        //Nineteen - Amethyst
        {
            hex: '#A869F6',
            rgb: '168, 105, 246',
            name: 'Amethyst'
        },
        //Twenty - Sunglow
        {
            hex: '#FFCD49',
            rgb: '255, 205, 73',
            name: 'Sunglow'
        },
        //TwentyOne - Rose
        {
            hex: '#E059A1',
            rgb: '224, 89, 161',
            name: 'Rose'
        },
        //TwentyTwo - Citron
        {
            hex: '#D1D26A',
            rgb: '209, 210, 106',
            name: 'Citron'
        },
        //TwentyThree - Feldgrau
        {
            hex: '#49676A',
            rgb: '73, 103, 106',
            name: 'Feldgrau'
        },
        //TwentyFour - Midnight green
        {
            hex: '#1E4044',
            rgb: '30, 64, 68',
            name: 'Midnight green'
        },
        //TwentyFive - Charcoal
        {
            hex: '#4A4D5E',
            rgb: '74, 77, 94',
            name: 'Charcoal'
        },
        //TwentySix - Payne's gray
        {
            hex: '#3F697F',
            rgb: '63, 105, 127',
            name: 'Paynes gray'
        },
        //TwentySeven - Pastel peach
        {
            hex: '#FFB899',
            rgb: '255, 184, 153',
            name: 'Pastel peach'
        },
        //TwentyEight - Pastel vanilla
        {
            hex: '#FFE6A4',
            rgb: '255, 230, 164',
            name: 'Pastel vanilla'
        },
        // TwentyNine - Pastel Green
        {
            hex: '#A5D6D0',
            rgb: '165, 214, 208',
            name: 'Pastel green'
        }
    ],
    text: {
        hex: '#1D2136',
        rgb: '29, 33, 54'
    },
    interactive: {
        hex: '#CD1423',
        rgb: '205, 20, 35'
    },
    interactiveContrast: {
        hex: '#FFFFFF',
        rgb: '255, 255, 255'
    },
    background: {
        hex: '#FFFFFF',
        rgb: '255, 255, 255'
    },
    modalOverlay: {
        hex: '#000000CC',
        rgb: 'rgba(0, 0, 0, 0.8)'
    },
    reso: {
        hex: '#FFF3D1',
        rgb: '255, 243, 209'
    },
    staff: {
        hex: '#212121',
        rgb: '33, 33, 33'
    },
    warsash: {
        hex: '#1d2136',
        rgb: '29, 33, 54'
    },
    campaigns: {
        clearing: {
            primary: {
                hex: '#CD1423',
                rgb: '205, 20, 35'
            },
            primaryContrast: {
                hex: '#FFFFFF',
                rgb: '255, 255, 255'
            },
            secondary: {
                hex: '#333333',
                rgb: '51, 51, 51'
            },
            secondaryContrast: {
                hex: '#FFFFFF',
                rgb: '255, 255, 255'
            }
        }
    }
};

export default colors;
